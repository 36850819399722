import { Translations } from './translations';
import { ErrorResponse } from './types';

const locale = import.meta.env.VITE_APP_LOCALE?.toLowerCase();
const attractEndpoint = import.meta.env.VITE_APP_API_ATTRACT_URL;

export const getData = async (amount: number) => {
  let errorMessage = '';
  const urls = [
    `${attractEndpoint}/v2/calculation/calculate/${locale}/${amount}`,
    `${attractEndpoint}/attract/v1/translations/${locale}/monthlypay`,
  ];

  const calculationPromise = fetch(urls[0])
    .then(async (res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          errorMessage = error?.error;
        });
      }
      return res.json();
    })
    .catch((error: ErrorResponse) => {
      if (error !== undefined) errorMessage = error;
      else errorMessage = 'Could not fetch calculation.';
      return null;
    });
  const translationsPromise = fetch(urls[1])
    .then(async (res) => {
      const data = await res.json();
      return data.data as Translations;
    });

  const [calculations, trans] = await Promise.all([
    calculationPromise,
    translationsPromise,
  ]).catch(() => {
    throw Error('Could not fetch translation.');
  });
  return [calculations, trans, errorMessage] as const;
};

export const getUsData = async () => {
  let errorMessage = '';
  const urls = [
    `${attractEndpoint}/attract/v1/translations/${locale}/monthlypay`,
  ];
  const products = [
    { productId: 'creditcard', placementId: '5ed3546f-7229-4484-a1f1-78b9f87200fa' },
    { productId: 'projektcard', placementId: '8d5566d6-6651-472e-afb9-405875f68a88' },
  ];
  const translationsPromise = fetch(urls[0])
    .then(async (res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          errorMessage = error?.error;
        });
      }
      const data = await res.json();
      return data.data as Translations;
    });
  const [trans] = await Promise.all([
    translationsPromise,
  ]).catch(() => {
    throw Error('Could not fetch translation.');
  });
  return [products, trans, errorMessage] as const;
};
