const locale = import.meta.env.VITE_APP_LOCALE.toLowerCase();

const getCookieValue = (name: string): string => {
  if (!window.ikea.cookieConsent?.hasConsent(3)) {
    return '';
  }
  const cookie = document.cookie.split('; ').find((row) => row.startsWith(`${name}=`));
  return cookie ? cookie.split('=')[1] : '';
};

const base64UrlDecode = (str: string): string => {
  const base64 = str.replace(/-/g, '+').replace(/_/g, '/');
  const decodedData = atob(base64);
  return decodeURIComponent(
    decodedData.split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''),
  );
};

const getClientArticleNumbers = (): string => {
  const path = window.location.pathname;
  if (path.includes('/p/')) {
    const match = path.match(/(\d+)\/?$/);
    return match ? match[1] : '';
  }
  if (path.includes('/shoppingcart/')) {
    const country = locale.split('/')[0];
    const cartData = JSON.parse(localStorage.getItem(`cart-state-${country}`) || '[]') as {
      itemNo: string
    }[];
    const itemNumbers = cartData.map((item) => item.itemNo);
    return itemNumbers.join(',');
  }
  return '';
};

const getClientLocation = (): 'pip' | 'cart' | '' => {
  const path = window.location.pathname;
  if (path.includes('/p/')) {
    return 'pip';
  }
  if (path.includes('/shoppingcart/')) {
    return 'cart';
  }
  return '';
};

const getClientStoreId = (): string => {
  const navState = getCookieValue('nav_state');
  if (!navState) {
    return '';
  }
  const decodedNavState = JSON.parse(decodeURIComponent(navState || '{}'));
  return decodedNavState.store?.id || '';
};

export const getPartyUID = (token: string): string => {
  if (!token) {
    return '';
  }
  try {
    const payload = token.split('.')[1];
    const decodedPayload = base64UrlDecode(payload);
    const parsedPayload = JSON.parse(decodedPayload);
    return parsedPayload['https://accounts.ikea.com/partyUId'] || '';
  } catch (error) {
    return '';
  }
};

export const getGuestID = (token: string): string => {
  if (!token) {
    return '';
  }
  try {
    const payload = token.split('.')[1];
    const decodedPayload = base64UrlDecode(payload);
    const parsedPayload = JSON.parse(decodedPayload);
    return parsedPayload.sub || '';
  } catch (error) {
    return '';
  }
};

const getHeaders = (): Record<string, string> => ({
  'Client-Article-Numbers': getClientArticleNumbers(),
  'Client-Origin': 'web',
  'Client-Location': getClientLocation(),
  'Client-Store-Id': getClientStoreId(),
  'Client-Party-Uid': getPartyUID(getCookieValue('idp_reguser')),
  'Client-Guest-Id': getGuestID(getCookieValue('guest')),
});

export default getHeaders;
